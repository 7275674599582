<template>
    <header class="header">
        <section class="header-inner">
            <router-link class="to-home" to="/">
                <i class="icon icon-home-small" />
                <span class="to-home-txt">首页</span>
            </router-link>
            <user-info class="user-info-simple" />
        </section>
    </header>
</template>
<script>
import userInfo from './userInfo'
export default {
    name: 'SimpleHeader',
    components: {
        userInfo
    }
}
</script>
<style lang="scss" scoped>
    .header {
        min-width: 1200px;
        height: 30px;
        font-size: 14px;
        background-color: $white;
    }
    .header-inner {
        position: relative;
        margin: 0 auto;
        width: 1200px;
        height: 100%;
        border-bottom: 1px solid $gray;
    }
    .to-home-txt {
        @include inlineBlock();
        margin-left: 6px;
    }
    a.to-home {
        @include inlineBlock();
        padding: 5px 0;
        color: $light;
        &:hover {
            color: $red;
        }
    }
</style>
